<template>
  <div>
    <headerIndex
      v-on:grid="currentView='grid'"
      v-on:list="currentView='list'"
      v-on:map="currentView='map'"
    ></headerIndex>
    <template v-if="currentView == 'list' && path == '/trackerAdmin'">
      <list/>
    </template>
    <template v-if="currentView == 'grid' && path == '/trackerAdmin'">
      <grid/>
    </template>
    <template v-if="currentView == 'map' && path == '/trackerAdmin'">
      <trackerMap/>
    </template>
  </div>
</template>

<script>
export default {
  components: {
    headerIndex: () => import('@core/spore-components/headerIndex/headerIndex.vue'),
    list: () => import('./ListAdmin.vue'),
    grid: () => import('./GridAdmin.vue'),
    trackerMap: () => import('./MapAdmin.vue')
  },
  data () {
    return {
      currentView: 'list',
      path: ''
    }
  },
  created () {
    this.path = this.$route.path
  }
}
</script>
